import React from 'react'
import * as Sentry from '@sentry/react'
import {FallbackRender} from './types'

export const ErrorBoundary: React.FC = ({children}) => (
  <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>{children}</Sentry.ErrorBoundary>
)

const ErrorBoundaryFallback: FallbackRender = ({error, componentStack}) => (
  <div className="p-10">
    <h1 className="text-xl">Sorry, something went wrong!</h1>
    <h2 className="text-sm">{error.toString()}</h2>
    <pre className="text-xs">{componentStack}</pre>
  </div>
)
