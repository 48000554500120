import React from 'react'
import {Note} from '../../../app/models'

export const NoteContent = ({note}: {note: Note}) => (
  <div
    style={{width: 350, height: 200}}
    className="px-5 py-4 overflow-hidden prose prose-sm prose-xs text-gray-900 dark:text-near-white dark:prose-dark backlink-html-context"
  >
    <div dangerouslySetInnerHTML={{__html: note.contentHtml}} />
  </div>
)
