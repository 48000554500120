import {usePreferenceStore} from '../../app/models/store/context'
import {useColorScheme} from '../../plugins/use-color-scheme'

type Theme = 'light' | 'dark'

export const useTheme = (): Theme => {
  const scheme = useColorScheme()
  const {theme: preferenceTheme} = usePreferenceStore()

  if (preferenceTheme === 'system') {
    return scheme
  } else {
    return preferenceTheme
  }
}
