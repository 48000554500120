import clsx from 'clsx'
import React from 'react'

interface TextInputProps {
  value?: string
  onChange?: (value: string) => void
  required?: boolean
  autoComplete?: string
  pattern?: string
  error?: boolean
}

export const TextInput: React.FC<TextInputProps> = ({
  value,
  onChange,
  required,
  autoComplete,
  pattern,
  error = false,
}) => (
  <input
    type="text"
    required={required}
    value={value}
    autoComplete={autoComplete}
    pattern={pattern}
    onChange={(event) => onChange?.(event.target.value)}
    className={clsx([
      'block rounded-md w-full px-3 py-2 sm:text-sm',
      'border border-gray-300 shadow-sm outline-none',
      'focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50',
      'dark:bg-dark-black-800 dark:border-gray-600 dark:ring-purple-800 dark:text-white',
      'transition-all duration-300',
      error &&
        'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
    ])}
  />
)
