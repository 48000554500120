import clsx from 'clsx'
import React from 'react'
import {FadeInTransition} from '../transition/fade-in-transition'
import {ModalProps} from './modal.props'

export const ColorModal: React.FC<ModalProps> = ({show = true, children}) => {
  return (
    <FadeInTransition in={show}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
          <div className="fixed inset-0 transition-opacity">
            <div
              className="absolute inset-0"
              style={{
                background:
                  '600% 500%/90% 90% radial-gradient(closest-side,rgba(228,79,79,.7),rgba(228,79,79,0)) no-repeat,600% 180%/90% 90% radial-gradient(closest-side,rgba(228,79,79,.7),rgba(228,79,79,0)) no-repeat,100% 150%/75% 50% radial-gradient(closest-side,rgba(250,250,218,.2),rgba(250,250,218,0)) no-repeat,-400% -100%/90% 80% radial-gradient(closest-side,rgba(250,250,218,.3),rgba(250,250,218,0)) no-repeat,-100% -250%/85% 80% radial-gradient(closest-side,rgba(94,225,249,.8),rgba(94,225,249,0)) no-repeat,-170% 100%/70% 60% radial-gradient(closest-side,rgba(94,225,249,.6),rgba(94,225,249,0)) no-repeat,50% 50%/100% 100% linear-gradient(30deg,#6e10ce 10%,rgba(110,16,206,0) 70%,hsla(0,0%,100%,0) 90%) no-repeat,linear-gradient(144deg,rgba(233,235,104,0),rgba(233,235,104,.3)) no-repeat,linear-gradient(90deg,rgba(104,184,235,.11),rgba(15,216,223,.11)) no-repeat,#fff',
              }}
            />
          </div>

          <div
            className={clsx([
              'bg-white rounded-3xl',
              'px-4 pt-5 pb-4 min-h-80',
              'flex flex-col',
              'text-left overflow-hidden shadow-xl',
              'transform transition-all',
              'sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6',
            ])}
            role="dialog"
          >
            {children}
          </div>
        </div>
      </div>
    </FadeInTransition>
  )
}
