import {debounce} from 'lodash'
import React, {useState} from 'react'
import Split from 'react-split-grid'
import {Gutter} from './gutter'
import {SplitPaneOptions} from './split-pane.props'

const SplitPane: React.FC<SplitPaneOptions> = ({
  children,
  name,
  direction = 'left',
  defaultSidebarWidth = 360,
}) => {
  const storageKey = `split-${name}`
  const getStoredColumns = () => localStorage.getItem(storageKey)
  const setStoredColumns = debounce((value: string) =>
    localStorage.setItem(storageKey, value),
  )
  const defaultColumns =
    direction == 'left'
      ? `${defaultSidebarWidth}px 2px auto`
      : `auto 2px ${defaultSidebarWidth}px`
  const storedColumns = getStoredColumns() || defaultColumns

  const [columns, setColumns] = useState(storedColumns)

  const handleDrag = (_direction: any, _track: any, gridTemplateStyle: string) => {
    setColumns(gridTemplateStyle)
    setStoredColumns(gridTemplateStyle)
  }

  return (
    <Split
      gridTemplateColumns={columns}
      onDrag={handleDrag}
      cursor="col-resize"
      render={({
        getGridProps,
        getGutterProps,
      }: {
        getGridProps: any
        getGutterProps: any
      }) => (
        <div className="grid h-screen" {...getGridProps()}>
          <div className="flex flex-col overflow-hidden">{children[0]}</div>

          <Gutter direction={direction} getGutterProps={getGutterProps} index={1} />

          <div className="flex flex-col overflow-hidden">{children[1]}</div>
        </div>
      )}
    />
  )
}

export {SplitPane}
