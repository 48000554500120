import {useEffect, useState} from 'react'

type ColorScheme = 'light' | 'dark'

export const useMatchMedia = (handler: (event: Event) => void) => {
  useEffect(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)')
    matchMedia.addEventListener('change', handler)
    return () => matchMedia.removeEventListener('change', handler)
  }, [])
}

const browser = typeof window != 'undefined'

export const useColorScheme = (): ColorScheme => {
  const matchMedia = browser
    ? window.matchMedia('(prefers-color-scheme: dark)')
    : {matches: false}

  const [scheme, setScheme] = useState<ColorScheme>(matchMedia.matches ? 'dark' : 'light')

  useMatchMedia(() => {
    setScheme(matchMedia.matches ? 'dark' : 'light')
  })

  return scheme
}
