import clsx from 'clsx'
import {X} from 'heroicons-react'
import React from 'react'
import {FadeInTransition} from '../transition/fade-in-transition'
import {ModalProps} from './modal.props'

export const FullModalDark: React.FC<ModalProps> = ({show = true, onClose, children}) => {
  return (
    <FadeInTransition in={show}>
      <div className="fixed z-50 inset-0 overflow-y-auto" role="modal">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black"></div>
          </div>

          {onClose && (
            <nav className="absolute right-0 top-0 px-4 py-4">
              <button
                role="button"
                aria-label="Close"
                onClick={onClose}
                className="text-near-white cursor-pointer flex items-center space-x-2 text-sm hover:text-gray-900 outline-none transition"
              >
                <span>Close</span>
                <span className="bg-dark-black-800 px-1.5 py-0.5 rounded-md text-xs">
                  Esc
                </span>
                <X size={15} />
              </button>
            </nav>
          )}

          <div
            className={clsx([
              'inline-block align-bottom',
              'px-4 py-4 relative',
              'transform transition-all',
              'text-gray-900',
            ])}
            role="dialog"
          >
            {children}
          </div>
        </div>
      </div>
    </FadeInTransition>
  )
}
