import clsx from 'clsx'
import React from 'react'
import {PortalBody} from '../portal-body/portal-body'
import {PopoverProps} from './types'

export const TooltipPopover: React.FC<PopoverProps> = ({coords, label}) => {
  const {left, top} = coords

  return (
    <PortalBody>
      <div
        className={clsx([
          'absolute flex flex-col bg-gray-800 rounded-md px-2 py-1 max-w-52',
          'text-white text-xs',
          'shadow-lg ring-1 ring-gray-200 ring-opacity-50 overflow-hidden z-50',
        ])}
        style={{left, top}}
      >
        {label}
      </div>
    </PortalBody>
  )
}
