import React from 'react'
import {FullModalDark} from '../'
import {SiteLogoImage} from './logo-image'

export const SiteModal: React.FC = ({children}) => (
  <FullModalDark show={true}>
    <div className="flex flex-col items-center justify-center flex-1">
      <div>
        <SiteLogoImage width={128} height={128} />
      </div>

      <div className="mt-8 sm:mt-20 text-center text-white">{children}</div>
    </div>
  </FullModalDark>
)
