import clsx from 'clsx'
import {Eye, EyeOff} from 'heroicons-react'
import React, {useState} from 'react'

interface PasswordInputProps {
  value: string
  onChange: (value: string) => void
  error?: boolean
  newPassword?: boolean
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  value,
  onChange,
  error = false,
  newPassword = false,
}) => {
  const [plaintext, setPlaintext] = useState(false)

  return (
    <>
      <div className="relative">
        <div
          title="Toggle input plaintext"
          onClick={() => setPlaintext(!plaintext)}
          className="absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer text-gray-300"
        >
          {plaintext ? <Eye /> : <EyeOff />}
        </div>

        <input
          type={plaintext ? 'input' : 'password'}
          autoComplete={newPassword ? 'new-password' : 'current-password'}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          required
          pattern="(?=.*[a-zA-Z])\S{8,}"
          className={clsx(
            'block rounded-md w-full px-3 py-2 sm:text-sm pl-10 pr-10',
            'border border-gray-300 shadow-sm outline-none',
            'focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50',
            'dark:bg-dark-black-800 dark:border-gray-600 dark:ring-purple-800 dark:text-white',
            'transition-all duration-300',
            error &&
              'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
          )}
        />
      </div>
    </>
  )
}
