import React from 'react'
import {Listbox} from '@headlessui/react'

export interface OptionProps {
  label: string
  value: string
}

export const Option: React.FC<OptionProps> = ({label, value}) => (
  <Listbox.Option key={value} value={value}>
    {({selected, active}) => (
      <div
        className={`${
          active || selected ? 'text-purple-500' : 'text-gray-900'
        } cursor-pointer select-none relative py-2 px-4`}
      >
        <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
          {label}
        </span>
      </div>
    )}
  </Listbox.Option>
)
