import {truncate} from 'lodash'
import React from 'react'
import {Note} from '../../../app/models'

export const shouldDisplayEnrichment = (note: Note): boolean => {
  return !!(note.enrichment?.person?.name?.fullName || note.enrichment?.company?.name)
}

const Person = ({person}: {person: any}) => {
  return (
    <div className="flex space-x-4">
      <div className="flex-none rounded-md overflow-hidden h-15 w-14 bg-coolgray-50 dark:bg-black">
        {person.avatar && (
          <div
            className="bg-contain bg-center bg-no-repeat inline-block h-full w-full"
            style={{backgroundImage: `url(${person.avatar})`}}
          />
        )}
      </div>

      <div className="flex-1 flex flex-col">
        <h3 className="text-purple-550 text-base">{person.name?.fullName}</h3>

        {person.bio && (
          <div className="text-sm text-gray-900 dark:text-near-white" title={person.bio}>
            {truncate(person.bio, {length: 100})}
          </div>
        )}
      </div>
    </div>
  )
}

const Company = ({company}: {company: any}) => {
  return (
    <div className="flex space-x-4">
      {
        <div className="flex-none rounded-md overflow-hidden h-15 w-14">
          <div
            className="bg-contain bg-center bg-no-repeat inline-block h-full w-full"
            style={{backgroundImage: `url(${company.logo})`}}
          />
        </div>
      }

      <div className="flex-1 flex flex-col">
        <h3 className="text-purple-550 text-base">{company.name}</h3>

        {company.description && (
          <div
            className="text-sm text-gray-900 dark:text-near-white"
            title={company.description}
          >
            {truncate(company.description, {length: 50})}
          </div>
        )}
      </div>
    </div>
  )
}

export const NoteEnrichment = ({note}: {note: Note}) => {
  const enrichment = note.enrichment || {}
  const {person, company} = enrichment

  return (
    <div
      style={{width: 300}}
      className="flex-1 flex flex-col justify-between space-y-2 px-3 py-3"
    >
      {person && <Person person={person} />}
      {company && <Company company={company} />}
    </div>
  )
}
