import React, {useState} from 'react'
import {PlaceholderImageProps} from './placeholder-image.props'

const PlaceholderImage: React.FC<PlaceholderImageProps> = ({
  src,
  className,
  children,
}) => {
  const [display, setDisplay] = useState(false)

  if (src) {
    const image = new Image()
    image.onload = () => setDisplay(true)
    image.src = src
  }

  if (src && display) {
    return <img src={src} className={className} />
  } else {
    return <>{children}</>
  }
}

export {PlaceholderImage}
