import React from 'react'
import clsx from 'clsx'
import {ButtonOptionProps} from './types'

export const ButtonGroupOption: React.FC<ButtonOptionProps> = ({
  children,
  onClick,
  position = 'middle',
  selected = false,
}) => (
  <a
    onClick={onClick}
    className={clsx([
      'relative inline-flex items-center px-4 py-2 cursor-pointer',
      'border border-gray-100 bg-white',
      'transition-all duration-100',
      'text-sm font-medium hover:bg-coolgray-50 text-gray-400',
      'dark:bg-dark-black-900 dark:border-gray-800',
      selected && 'bg-coolgray-50 text-purple-500',
      position == 'left' && 'rounded-l-md',
      position == 'right' && 'rounded-r-md',
    ])}
  >
    {children}
  </a>
)
