import clsx from 'clsx'
import React from 'react'

interface GutterProps {
  index: number
  direction: string
  getGutterProps: (column: string, refcol: number) => void
}

const Gutter: React.FC<GutterProps> = ({getGutterProps, index, direction}) => (
  <div
    className={clsx(
      'bg-coolgray-50 dark:bg-dark-black-800 hover:bg-purple-550 border-gray-100 dark:border-gray-900',
      direction == 'left' ? 'border-r' : 'border-l',
      'transition-all duration-500',
    )}
    style={{cursor: 'col-resize', width: '2px'}}
    {...getGutterProps('column', index)}
  />
)

export {Gutter}
