import React from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {Option} from './option'
import clsx from 'clsx'

export interface SelectProps {
  label: string
  selectedValue: string
  onChange: (value: string) => void
  options: [value: string, label: string][]
}

export const Select: React.FC<SelectProps> = ({
  label,
  selectedValue,
  options,
  onChange,
}) => {
  const selectedOption = options.find(([value]) => value === selectedValue)
  const [, selectedLabel] = selectedOption || []

  return (
    <div className="flex items-center justify-center max-w-64">
      <div className="w-full max-w-xs mx-auto">
        <Listbox
          as="div"
          className="space-y-1"
          value={selectedValue}
          onChange={(value) => onChange(value)}
        >
          {({open}) => (
            <>
              <Listbox.Label className="block text-sm leading-5 font-medium text-gray-700 dark:text-near-white">
                {label}
              </Listbox.Label>

              <div className="relative">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button
                    className={clsx([
                      'cursor-default relative w-full',
                      'rounded-md border border-gray-300 bg-white',
                      'pl-3 pr-10 py-2 text-left',
                      'focus:outline-none focus:shadow-outline-purple focus:border-purple-300',
                      'transition duration-300 sm:text-sm sm:leading-5',
                      'dark:bg-dark-black-800 dark:border-gray-600',
                    ])}
                  >
                    <span className="block truncate">{selectedLabel}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  show={open}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                >
                  <Listbox.Options
                    static
                    className={clsx([
                      'max-h-60 rounded-md py-1 text-base leading-6 shadow-xs',
                      'ring-1 ring-gray-200 ring-opacity-50',
                      'overflow-auto focus:outline-none sm:text-sm sm:leading-5',
                      'dark:bg-dark-black-800',
                    ])}
                  >
                    {options.map(([value, label]) => (
                      <Option key={value} value={value} label={label} />
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  )
}
