import React, {useRef, useState} from 'react'
import {Popover} from './popover'
import {Coords, NotePreviewProps} from './types'

const NotePreview: React.FC<NotePreviewProps> = ({note, children}) => {
  const elementRef = useRef(null)
  const [coords, setCoords] = useState<Coords | null>(null)

  return (
    <span
      ref={elementRef}
      onMouseOver={(event) => {
        setCoords({left: event.clientX, top: event.clientY})
      }}
      onMouseOut={() => {
        setCoords(null)
      }}
      onMouseMove={(event) => {
        setCoords({left: event.clientX, top: event.clientY})
      }}
    >
      {coords && note && <Popover coords={coords} note={note} />}
      {children}
    </span>
  )
}

export {NotePreview}
