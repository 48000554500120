import clsx from 'clsx'
import React, {MouseEventHandler} from 'react'

interface SubmitButtonProps {
  loading?: boolean
  label?: string
  loadingLabel?: string
  onClick?: MouseEventHandler
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  label = 'Submit',
  loadingLabel = 'Loading...',
  loading = false,
  onClick,
}) => {
  return (
    <span className="flex w-full rounded-md shadow-sm">
      <button
        type="submit"
        onClick={onClick}
        disabled={loading}
        className={clsx(
          'inline-flex justify-center w-full shadow-sm relative min-w-28',
          'rounded-md border border-transparent px-4 py-2 bg-purple-600',
          'text-base leading-6 font-medium text-white sm:text-sm sm:leading-5',
          'hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple',
          'active:bg-purple-700',
          'transition ease-in-out duration-300',
          loading && 'opacity-50 cursor-auto',
        )}
      >
        {loading && (
          <>
            <svg
              className="animate-spin h-5 w-5 text-white absolute left-0 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </>
        )}

        {loading ? loadingLabel : label}
      </button>
    </span>
  )
}
