import clsx from 'clsx'
import React from 'react'
import {FadeInTransition} from '../transition/fade-in-transition'
import {ModalProps} from './modal.props'

const Modal: React.FC<ModalProps> = ({show = true, onClose, children}) => {
  return (
    <FadeInTransition in={show}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
          <div className="fixed inset-0 transition-opacity">
            <div
              className="absolute inset-0 bg-gray-100 dark:bg-dark-black-900"
              onClick={onClose}
            ></div>
          </div>

          <div
            className={clsx([
              'inline-block align-bottom bg-white rounded-lg',
              'px-4 pt-5 pb-4',
              'text-left overflow-hidden shadow-xl',
              'transform transition-all',
              'sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6',
              'dark:text-white dark:bg-dark-black-800',
            ])}
            role="dialog"
          >
            {children}
          </div>
        </div>
      </div>
    </FadeInTransition>
  )
}

export {Modal}
