import clsx from 'clsx'
import React from 'react'
import {ButtonProps} from './button.props'

const WhiteButton: React.FC<ButtonProps> = ({onClick, className = '', children}) => {
  return (
    <span className={`${className} inline-flex rounded-md hover:shadow-sm`}>
      <button
        onClick={onClick}
        type="button"
        className={clsx([
          'inline-flex items-center px-4 py-2 shadow-sm',
          'bg-white dark:bg-transparent',
          'rounded-md border border-transparent dark:border-gray-600',
          'text-sm leading-4 font-medium text-gray-700',
          'dark:text-near-white dark:hover:border-purple-500 dark:hover:text-purple-500 hover:text-purple-500',
          'focus:outline-none focus:border-purple-300 focus:shadow-outline-purple active:bg-coolgray-50',
          'transition-all ease-in-out duration-300',
          'cursor-pointer space-x-1.5',
        ])}
      >
        {children}
      </button>
    </span>
  )
}

export {WhiteButton}
