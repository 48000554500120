import React from 'react'
import {calculateBestPosition} from 'calculate-position'
import {PortalBody} from '..'
import {Note} from '../../app/models'
import {useDimensions} from '../../plugins/use-dimensions'
import {NoteBook, shouldDisplayBook} from './popover/note-book'
import {NoteContent} from './popover/note-content'
import {NoteEnrichment, shouldDisplayEnrichment} from './popover/note-enrichment'
import {PopoverProps} from './types'

type PopoverType = 'content' | 'enrichment' | 'book'

const getNotePopoverType = (note: Note): PopoverType => {
  if (shouldDisplayEnrichment(note)) {
    return 'enrichment'
  } else if (shouldDisplayBook(note)) {
    return 'book'
  } else {
    return 'content'
  }
}

const Popover: React.FC<PopoverProps> = ({note, coords, width = 350, margin = 10}) => {
  const [{height}, ref] = useDimensions()

  if (!note?.contentHtml) return null

  const anchor = {
    ...coords,
    width: margin,
    height: margin,
  }

  const dimensions = {
    width,
    height: height || 167,
  }

  const {left, top} = calculateBestPosition({anchor, dimensions})

  const type = getNotePopoverType(note)

  const render = () => {
    switch (type) {
      case 'enrichment':
        return <NoteEnrichment note={note} />
      case 'book':
        return <NoteBook note={note} />
      case 'content':
        return <NoteContent note={note} />
    }
  }

  return (
    <PortalBody>
      <div
        ref={ref}
        className="absolute flex flex-col bg-white dark:bg-dark-black-800 rounded-md shadow-lg ring-1 ring-gray-200 dark:ring-purple-700 ring-opacity-50 overflow-hidden z-30"
        style={{left, top}}
      >
        {render()}
      </div>
    </PortalBody>
  )
}

export {Popover}
