import React, {useEffect} from 'react'
import clsx from 'clsx'
import {observer} from 'mobx-react-lite'
import {useTheme} from './use-theme'
import electron from '../../services/electron'
import {usePreferenceStore} from '../../app/models/store/context'

export const Theme: React.FC = observer(({children}) => {
  const theme = useTheme()
  const {theme: preferenceTheme} = usePreferenceStore()

  // Sync theme to Electron
  useEffect(() => {
    electron?.setTheme?.(preferenceTheme)
  }, [preferenceTheme])

  return (
    <div
      className={clsx([theme === 'dark' ? 'dark' : 'light', 'select-none antialiased'])}
    >
      <div className="bg-white dark:bg-dark-black-900 dark:text-near-white">
        {children}
      </div>
    </div>
  )
})
