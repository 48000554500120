import React from 'react'
import {ButtonProps} from './button.props'

const PrimaryButton: React.FC<ButtonProps> = ({onClick, className = '', children}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${className} inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-300 cursor-pointer space-x-1`}
    >
      {children}
    </button>
  )
}

export {PrimaryButton}
export {PrimaryButton as Button}
