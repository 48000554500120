import Link from 'next/link'
import React from 'react'
import {SiteLogoImage} from '..'

export const SiteFooter = () => (
  <footer>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 md:pt-32">
      <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-900">
        <div className="sm:col-span-12 lg:col-span-3">
          <div className="mb-2">
            <Link href="/">
              <a className="block w-8" aria-label="Reflect">
                <SiteLogoImage className="w-full" />
              </a>
            </Link>
          </div>
          <div className="text-sm text-gray-400">
            <Link href="/terms">
              <a className="text-gray-400 hover:text-gray-300 transition duration-200 ease-in-out">
                Terms
              </a>
            </Link>
            <span> · </span>
            <Link href="/privacy">
              <a className="text-gray-400 hover:text-gray-300 transition duration-200 ease-in-out">
                Privacy Policy
              </a>
            </Link>
          </div>
        </div>
      </div>

      <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-900">
        <div className="text-sm text-gray-400 mr-4">
          Made by{' '}
          <a className="text-purple-600" href="https://reflect.app/">
            Reflect App, LLC
          </a>
          . All rights reserved.
        </div>
      </div>
    </div>
  </footer>
)
