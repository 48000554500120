import React, {useRef, useState} from 'react'
import {TooltipPopover} from './tooltip-popover'
import {TooltipCoords, TooltipProps} from './types'

const Tooltip: React.FC<TooltipProps> = ({label, children}) => {
  const elementRef = useRef<HTMLSpanElement>(null)
  const [coords, setCoords] = useState<TooltipCoords | null>(null)

  const openPopover = () => {
    if (!elementRef.current) return

    const domRect: DOMRect = elementRef.current.getBoundingClientRect()

    setCoords({
      left: domRect.left + domRect.width + 5,
      top: domRect.top,
    })
  }

  const closePopover = () => setCoords(null)

  return (
    <span
      ref={elementRef}
      onMouseOver={() => openPopover()}
      onMouseOut={() => closePopover()}
    >
      {coords && <TooltipPopover coords={coords} label={label} />}
      {children}
    </span>
  )
}

export {Tooltip}
