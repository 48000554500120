import React from 'react'
import {ButtonProps} from './button.props'
import clsx from 'clsx'

export const PseudoButton: React.FC<ButtonProps> = ({
  onClick,
  className = '',
  small = false,
  children,
}) => {
  return (
    <span className="inline-flex">
      <span
        onClick={onClick}
        className={clsx([
          className,
          small ? 'px-4 py-2' : 'px-4 py-2 md:px-6 md:py-3',
          'inline-flex items-center space-x-2 cursor-pointer',
          'border border-transparent rounded-md',
          'text-sm leading-5 font-medium',
          ' text-indigo-600 bg-indigo-100 hover:bg-indigo-50',
          'focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo',
          'active:bg-indigo-200',
          'dark:bg-dark-black-900 dark:border dark:border-gray-600 dark:text-near-white ',
          'dark:hover:bg-dark-black-900 dark:hover:text-purple-500 dark:hover:border-purple-500',
          'transition-all ease-in-out duration-500',
        ])}
      >
        {children}
      </span>
    </span>
  )
}
