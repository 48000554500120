import React from 'react'
import electron from '../../services/electron'
import {LinkProps} from './link-props'

const openWindow = (href: string) => {
  openExternal(href) || window.open(href)
}

const openExternal = (href: string) => {
  if (href && electron?.openExternal) {
    return electron.openExternal(href)
  } else {
    return false
  }
}

const Link: React.FC<LinkProps> = ({href, className, children}) => (
  <a
    href={href}
    className={className}
    target="_blank"
    rel="noreferrer"
    onClick={(e) => {
      if (openExternal(href)) {
        e.preventDefault()
        return false
      }
    }}
  >
    {children}
  </a>
)

export {Link, openExternal, openWindow}
