import React from 'react'
import * as Sentry from '@sentry/react'
import {FallbackRender, HtmlCallback} from './types'

interface Props {
  getHtml: HtmlCallback
}

export const ErrorBoundaryFallback: React.FC<Props> = ({children, getHtml}) => (
  <Sentry.ErrorBoundary fallback={buildErrorBoundaryFallback(getHtml)}>
    {children}
  </Sentry.ErrorBoundary>
)

const buildErrorBoundaryFallback = (getHtml: HtmlCallback) => {
  const errorBoundaryFallback: FallbackRender = ({error, componentStack}) => (
    <div>
      <h1 className="text-xl">Sorry, something went wrong!</h1>
      <h2 className="text-sm">{error.toString()}</h2>
      <pre className="text-xs">{componentStack}</pre>
      <div
        className="error-boundary pt-20"
        dangerouslySetInnerHTML={{__html: getHtml()}}
      />
    </div>
  )

  return errorBoundaryFallback
}
