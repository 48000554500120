import {isEqual} from 'lodash'
import {RefObject, useRef, useState, useEffect} from 'react'

type DOMRectRef = [rect: DOMRect, ref: RefObject<HTMLDivElement>]

export const useDimensions = (): DOMRectRef => {
  const ref = useRef<HTMLDivElement>(null)

  const [rect, setRect] = useState<DOMRect>(DOMRect.fromRect())

  useEffect(() => {
    if (!ref.current) return

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      requestAnimationFrame(() => {
        for (const entry of entries || []) {
          if (isEqual(entry.contentRect, rect)) continue
          if (!ref.current) continue
          setRect(entry.contentRect)
        }
      })
    })

    observer.observe(ref.current)

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref.current])

  return [rect, ref]
}
