import {truncate} from 'lodash'
import React from 'react'
import {Note} from '../../../app/models'
import {useBookStore} from '../../../app/models/store/context'
import {Link} from '../../link/link'

export const shouldDisplayBook = (note: Note): boolean => {
  const bookStore = useBookStore()

  return !!(note.contentMetadataAsin && bookStore.findByAsin(note.contentMetadataAsin))
}

export const NoteBook = ({note}: {note: Note}) => {
  const bookStore = useBookStore()
  const asin = note.contentMetadataAsin
  const book = asin ? bookStore.findByAsin(asin) : null

  if (!book) return null

  return (
    <div style={{width: 300}} className="flex-1 flex px-3 py-3">
      <div className="overflow-hidden h-32 w-20 flex-none rounded-md">
        <div
          className="bg-cover bg-center bg-no-repeat inline-block h-full w-full"
          style={{backgroundImage: `url(${book.coverSrc})`}}
        />
      </div>

      <div className="flex-1 ml-4 space-y-1">
        <h3 className="text-purple-550 text-sm" title={book.title}>
          <Link href={book.listingUrl}>{truncate(book.title, {length: 100})}</Link>
        </h3>
        <p className="text-sm text-gray-800 dark:text-near-white">
          {book.authors.join(', ')}
        </p>
      </div>
    </div>
  )
}
