import React from 'react'
import {Transition} from 'react-transition-group'
interface FadeInTransitionProps {
  in?: boolean
  duration?: number
}

const FadeInTransition: React.FC<FadeInTransitionProps> = ({
  in: isIn,
  children,
  duration = 120,
}) => {
  const defaultStyle = {
    transition: `opacity ${duration}ms`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: {opacity: 1},
    entered: {opacity: 1},
    exiting: {opacity: 0},
    exited: {opacity: 0},
    unmounted: {},
  }

  return (
    <Transition timeout={duration} in={isIn} mountOnEnter={true} unmountOnExit={true}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}

export {FadeInTransition}
