import {createContext, useContext} from 'react'
import {RootStore} from './root-store'

/**
 * Create a context we can use to
 * - Provide access to our stores from our root component
 * - Consume stores in our screens (or other components, though it's
 *   preferable to just connect screens)
 */
const RootStoreContext = createContext<RootStore>({} as RootStore)

/**
 * The provider our root component will use to expose the root store
 */
export const RootStoreProvider = RootStoreContext.Provider

/**
 * A hook that screens can use to gain access to our stores, with
 * `const { someStore, someOtherStore } = useRootStore()`,
 * or less likely: `const rootStore = useRootStore()`
 */
export const useRootStore = () => useContext(RootStoreContext)

export function useNoteStore() {
  const {noteStore} = useRootStore()

  if (!noteStore) throw new Error('blank noteStore')

  return noteStore
}

export function useUserStore() {
  const {userStore} = useRootStore()
  return userStore
}

export function useContactStore() {
  const {contactStore} = useRootStore()
  return contactStore
}

export function usePreferenceStore() {
  const {preferenceStore} = useRootStore()
  return preferenceStore
}

export function useCredentialStore() {
  const {credentialStore} = useRootStore()
  return credentialStore
}

export function useGraphStore() {
  const {graphStore} = useRootStore()
  return graphStore
}

export function useBookStore() {
  const {bookStore} = useRootStore()
  if (!bookStore) throw new Error('blank bookStore')
  return bookStore
}

export function useNotificationStore() {
  const {notificationStore} = useRootStore()
  return notificationStore
}

export function useMeetingStore() {
  const {meetingStore} = useRootStore()
  return meetingStore
}
